@import '@styles/variables';

.about-us {
  max-width: $container-size;
  margin: 0 auto;
  padding: $spacing-md;

  .about-us__title {
    margin: 0 auto;
    max-width: 460px;
    text-align: center;
  }

  p {
    margin-bottom: $spacing-sm;
    font-size: 16px;
    line-height: 1.6;
    color: $color-text;
    font-family: $font-texts;
  }

  ul {
    padding-left: 20px;
    margin-bottom: $spacing-md;

    li {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.5;
      font-family: $font-texts;
      color: $color-text;
    }
  }

  .founder-name {
    color: $v2-primary-900;
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }

  .founder-description {
    color: $v2-text;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    font-family: $font-texts;

    p {
      margin: 0 0 10px;
    }

    strong {
      color: $v2-primary-900;
      font-weight: 600;
    }
  }

  .founders {
    display: flex;
    flex-direction: column;
    gap: $spacing-lg;
    margin: $spacing-lg 0;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: center;
    }

    .founder {

      img {
        height: 370px;
        margin-bottom: $spacing-xs;
        object-fit: cover;
        width: 295px;
        margin: 0 auto;
      }

      p {
        margin: 0 8px;
        font-size: 16px;
        font-family: $font-texts;

        strong {
          display: block;
          font-size: 18px;
          color: $v2-neutral-900;
          font-family: $font-texts;
        }
      }
    }
  }
}
