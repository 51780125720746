@use '@styles/variables' as *;

.comments__container {
  padding: 0 20px;

  @media (min-width: $breakpoint-lg) {
    padding: 0;
  }

  .ant-card {
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #00000040;
  }

  .ant-card-body {
    padding: 0;
  }

  .comments__card-header {
    background-size: cover;
    background-position: center;
    height: 178px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 8px 8px 0 0;
  }

  .comments__video-wrapper {
    border-radius: 8px 8px 0 0;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .comments__video-blur-bg {
    filter: blur(20px);
    height: 100%;
    object-fit: cover;
    position: absolute;
    transform: scale(1.1);
    width: 100%;
    z-index: 0;
  }

  .comments__card-header--video {
    background-color: transparent;
    border-radius: 8px 8px 0 0;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 350px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .comments__card-comment {
    color: $v2-neutral-900;
    font-size: 14px;
    padding: 0 20px;
    margin: 0;
  }

  .comments__card-user {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 20px;

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .comments__card-user-name {
      font-weight: 600;
      font-family: $font-primary;
      display: block;
    }

    .comments__card-user-type {
      color: $v2-neutral-400;
      display: block;
      font-family: $font-primary;
      font-weight: 400;
    }
  }

  .swiper {
    height: auto;
    margin-bottom: 24px;
    transition: all 0.3s ease-in-out;
  }

  .comments__card-action {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    > a {
      color: $v2-neutral-900;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .comments__footer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    > a {
      color: $v2-neutral-900;
      font-family: $font-primary;
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .comments__video-thumbnail {
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    height: 178px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .comments__video-thumbnail img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .comments__play-button {
    background: $v2-secondary-400;
    border-radius: 50%;
    cursor: pointer;
    height: 48px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    z-index: 1;
  }

  .comments__video-overlay {
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.35); /* transparência branca */
    z-index: 1;
    border-radius: 8px 8px 0 0;
  }

  .comments__play-button::before {
    border-bottom: 8px solid transparent;
    border-left: 12px solid white;
    border-top: 8px solid transparent;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -50%);
    width: 0;
  }
}
