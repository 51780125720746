@use '@styles/variables' as *;

.lateral-menu {
  border-radius: 0 16px 16px 0;

  .ant-drawer-content-wrapper {
    padding: 24px;
    box-shadow: none !important;
    width: 100vw !important;

    @media (min-width: $breakpoint-md) {
      max-width: 420px;
    }
  }

  .ant-drawer-content {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }

  .ant-drawer-body {
    display: flex;
    flex-flow: column nowrap;
  }

  .lateral-menu__logo {
    margin-bottom: 72px;
    margin-top: 24px;
    width: 190px;
  }

  .menu-body {
    .lateral-menu__item {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      gap: 5px;
      margin-bottom: 24px;

      .anticon {
        font-size: 1rem;
        margin-right: 16px;
      }

      .lateral-menu__label {
        color: $color-gray-title;
        font-family: $font-texts;
      }
    }

    .lateral-menu__account {
      color: $color-gray-title;
      display: block;
      font-family: $font-texts;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }

  .menu-footer {
    align-items: center;
    border-radius: 8px;
    border: 1px solid $v2-neutral-200;
    display: flex;
    margin-top: auto;
    padding: 16px;

    &.menu-footer__unlogged {
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;

      .menu-footer__label {
        color: $v2-neutral-900;
        font-size: 16px;
        font-weight: 700;
      }

      a {
        color: $v2-neutral-800 !important;
        font-family: $font-texts;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &.menu-footer__logged {
      align-items: center;
      flex-flow: row nowrap;
      justify-content: space-between;

      .menu-footer__account {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
      }

      .menu-footer__container {
        display: flex;
        flex-flow: column wrap;
        margin-left: 0;
      }

      .menu-footer__logout {
        background: none;
        border: none;
        color: inherit;
        cursor: pointer;
        font: inherit;
        outline: inherit;
        padding: 0;
      }
    }

    .menu-footer__profile {
      cursor: pointer;
      margin-top: 4px;
    }

    .menu-footer__avatar {
      border-radius: 50%;
      height: 64px;
      width: 64px;
      object-fit: cover;
    }

    .menu-footer__container {
      display: flex;
      flex-flow: column wrap;
      margin-left: 16px;

      .menu-footer__username {
        color: $color-gray-title;
        font-family: $font-texts;
        font-feature-settings: 'pnum' on, 'lnum' on;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 4px;
      }

      .menu-footer__email {
        color: $color-gray-title;
        font-family: $font-texts;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

}
