@use '@styles/variables' as *;

.home__container {
  max-width: $container-size;
  margin: 0 auto;

  .home__content {
    transform: translateY(-80px);

    @media (min-width: $breakpoint-lg) {
      transform: translateY(-85px);
    }
  }
}
